import { useState } from 'react';

export default function Myform({setResult}){

  const [inputs, setInputs] = useState({'reference':"",'longitudinal':"",'transversal':"",'epaisseur':""});
  const [prevInputs, setPrevInputs] = useState({'reference':"",'longitudinal':"",'transversal':"",'epaisseur':""});
  const [err, setErr] = useState({'reference':false,'longitudinal':false,'transversal':false,'epaisseur':false});
  const [refErr, setRefErr] = useState(false);
  const [epErr, seEpErr] = useState(false);
  const [diamErr, setDiamErr] = useState(false);
  const [longErr, setLongErr] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    

    
  }
  const handleBlurRef = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    console.log(inputs[name]);
    console.log(value);
    if (inputs[name]!== prevInputs[name]){
      setPrevInputs(inputs);
      setResult({'X':"?",'Y':"?",'error':''});
    }
    
  }
  const handleBlur = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    console.log(inputs[name]);
    console.log(value);
    if (inputs[name]!== prevInputs[name]){
      setPrevInputs(inputs);
      setResult({'X':"?",'Y':"?",'error':''});
      console.log(/^[0-9]{1,2},[0-9]{1,2}/.test(value))
      if(/^([0-9]){1,2}((,|.)([0-9]){1,2}){0,1}$/.test(value)){
        setErr({...err,[name]:false})
      }
      else{
        setErr({...err,[name]:true})
      }
    }
    
  }

const calculate = (data)=>{

  
  console.log(inputs);
/*  fetch('/calc?ref=' + inputs.reference+ '&long=' + inputs.longitudinal + '&trans=' + inputs.transversal + '&ep=' +inputs.epaisseur).then(res => res.json()).then(data => {
    setResult({'X':data.X,'Y':data.Y}); */
    fetch('/calc?ref=' + inputs.reference+ '&long=' + inputs.longitudinal + '&trans=' + inputs.transversal + '&ep=' +inputs.epaisseur).then(res => res.json()).then(data => {
      console.log(data);

      setResult({'X':data.X,'Y':data.Y,'error':data.error});
  });
}
return(
<div>
  <table className="margin-center">
    <tbody>
    <tr>
      <td className="text-right"><div style={{width: '200px',height: '50px'}}><img style={{width: '200px',height: '100px'}} src="./ref.png"/></div></td>
      <td className="text-right"><label> Référence : </label></td>
      <td className="text-left"><input className={err['reference'] ? "error" :""} name="reference" type="text" size="15" value={inputs.reference} onChange={handleChange} onBlur={handleBlurRef}/></td>
     </tr><tr>
      <td className="text-right"><div style={{width: '200px',height: '100px'}}><img style={{width: '100px',height: '100px'}} src="./Ep.png"/></div></td>  
      <td className="text-right"><label> Epaisseur tourillon : </label></td> 
      <td className="text-left"><input className={err['epaisseur'] ? "error" :""} name="epaisseur" type="text" size="3" maxLength="5" value={inputs.epaisseur} onChange={handleChange} onBlur={handleBlur}/></td>
    </tr><tr>
    <td className="text-right"><div style={{width: '200px',height: '100px'}}><img style={{width: '100px',height: '100px'}} src="./miss-3.png"/></div></td>     
      <td className="text-right"><label> Diagonale Diamètre : </label></td> 
      <td className="text-left"><input className={err['transversal'] ? "error" :""} name="transversal" type="text" size="3" maxLength="5" value={inputs.transversal} onChange={handleChange} onBlur={handleBlur}/></td>
    </tr><tr>
    <td className="text-right"><div style={{width: '200px',height: '100px'}}><img style={{width: '100px',height: '100px'}} src="./miss-2.png"/></div></td>    
      <td className="text-right"><label> Diagonale logueur : </label></td>
      <td className="text-left"><input className={err['longitudinal'] ? "error" :""} name="longitudinal" type="text" size="3" maxLength="5" value={inputs.longitudinal} onChange={handleChange} onBlur={handleBlur}/></td>
        
    </tr>
    <tr>
      <td className="text-center" colSpan="3"><button onClick={calculate}>Valider</button></td>
    </tr>       
    </tbody>
  </table>
</div>

);
}