import React, { useState } from 'react';
import Myform from '../form';


export default function Calculator(){
  const [results, setResults] = useState({'X':0,'Y':0,'error':''});
  return(
    <div>
      <Myform setResult={setResults}/>
      {results.error==='' ?  (
        <>
        <p className='answer'>Correction {results.X>0 ? "droite" : "gauche"} = {results.X>0 ? results.X : -results.X}</p>
        <p className='answer'>Correction arrière {results.Y>0 ? "haut" : "bas"} = {results.Y>0 ? results.Y : -results.Y}</p>
        </>
        ) : <p className='error'>{results.error}</p>}
    </div>

)
}       