import './App.css';

import Layout from './pages/layout.js'
import Admin from './pages/admin.js'
import Calculator from './pages/calculator.js'

import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Calculator />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
    
  )
}
