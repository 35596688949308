import { ChangeEvent, useState } from 'react';

function FileUploadSingle() {
  const [file, setFile] = useState();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    const data= new FormData();
    console.log(file.name);
    data.append('file',file);
    data.append('filename',file.name);
    // 👇 Uploading the file using the fetch API to the server
    fetch('/upload', {
      method: 'POST',
      body: data,
      // 👇 Set headers manually for single file upload
      /*headers: {
        'content-type': file.type,
        'content-length': `${file.size}`, // 👈 Headers need to be a string
      },*/
    })
      .then((res) => res.json())
      .then((data) => {console.log(data); data.answer="success"? alert("success"):alert("echec")})
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />

      <div>{file && `${file.name} - ${file.type}`}</div>

      <button onClick={handleUploadClick}>Upload</button>
    </div>
  );
}

export default FileUploadSingle;