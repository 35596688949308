import {Link, Outlet,useLocation} from "react-router-dom";


export default function Layout() {
  const location=useLocation();
  return (   
    <div className="App">
      <header className="App-header">
        <div className="margin-left" >
        <Link to={location.pathname!=="/admin" ? "/admin" : "/"}>{location.pathname!=="/admin" ? "Admin" : "Calc"}</Link>  

        </div><div className="margin-center" >
          Missmatch Calculator
        </div>
        
      </header>
      
      <Outlet/>

      
    </div> 

  )
}