import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModalLogin = ({onClose,isLogged}) => {
  const [pwd, setPwd] = useState("");
  const navigate=useNavigate();
  const handleSubmit= ()=>{
    fetch('/auth?pass=' + pwd).then(res => res.json()).then(data => {
      if(data.auth){
        isLogged(true);
        onClose()
      }
      else{
        isLogged(false);
        navigate("/");
      }
  });
    
  }
  const handleChange= (event)=>{
    console.log(event)
    setPwd(event.target.value);
  }

  return (
    <>
    <div className='modal-back' onClick={handleSubmit}></div>
    <div className="modal">
        <p>Mot de passe :</p>
        <input type="password" value={pwd} onChange={handleChange}/>
        <button onClick={handleSubmit}>Valider</button>
            </div></>
  );
};

export default ModalLogin;