import FileUploadSingle from '../upload';
import ModalLogin from './modal_login';
import React, { useState } from 'react';
import {createPortal} from 'react-dom';

export default function Admin( ){
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  return(
    isLogged ?  <FileUploadSingle/> : (createPortal(<ModalLogin onClose={()=>setIsModalOpen(false)} isLogged={(bool)=>{setIsLogged(bool)}} />,document.getElementById('modal-root')))
  )
}
